import styled from 'styled-components';

export const Container = styled.div``;

export const Navbar = styled.div`
  height: 200px;
  width: 100%;
  z-index: 998;
  top: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  padding: 0 30px;

  section {
    display: none;
  }

  @media (max-width: 480px) {
    padding: 10px 10px;

    div {
      display: none;
    }

    section {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: center;

      svg {
        cursor: pointer;
      }
    }

    nav {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      height: 100%;
      width: 100%;
      position: fixed;
      z-index: 1;
      top: 0;
      left: 0;
      background-color: rgba(14, 119, 252, 0.93);
      overflow-x: hidden;

      aside {
        padding: 10px 10px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: flex-end;
        width: 100vw;
      }

      ul {
        height: 80vh;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex-direction: column;

        li {
          text-align: center;
          font-size: 40px;
        }
      }
    }
  }

  @media (max-width: 767px) and (min-width: 481px) {
    padding: 10px 10px;

    div {
      display: none;
    }

    section {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: center;

      svg {
        cursor: pointer;
      }
    }

    nav {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      height: 100%;
      width: 100%;
      position: fixed;
      z-index: 1;
      top: 0;
      left: 0;
      background-color: rgba(14, 119, 252, 0.93);
      overflow-x: hidden;

      aside {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: flex-end;
        width: 100vw;
      }

      ul {
        height: 80vh;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex-direction: column;

        li {
          font-size: 40px;
        }
      }
    }
  }

  @media (min-width: 768px) and (max-width: 979px) {
    padding: 10px 10px;

    div {
      display: none;
    }

    section {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: center;

      svg {
        width: 40px;
        height: 40px;
        cursor: pointer;
      }
    }

    nav {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      height: 100%;
      width: 100%;
      position: fixed;
      z-index: 1;
      top: 0;
      left: 0;
      background-color: rgba(14, 119, 252, 0.93);
      overflow-x: hidden;

      aside {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: flex-end;
        width: 100vw;

        svg {
          height: 40px;
          width: 40px;
        }
      }

      ul {
        height: 80vh;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex-direction: column;

        li {
          font-size: 40px;
        }
      }
    }
  }
`;

export const Navbarcontent = styled.div`
  height: 150px;
  max-width: 1500px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  nav {
    display: flex;
    align-items: center;

    img {
      margin-right: 0px;
      padding-right: 20px;
      transition: all 0.5s;
      cursor: pointer;

      :hover {
        transform: rotate(-360deg);
      }
    }

    p {
      color: #fff;
      font-size: 47px;
      font-family: Poppins, Helvetica Neue, Arial, sans-serif;

      :nth-child(3) {
        color: #68bae5;
      }
    }
  }

  aside {
    display: flex;
    align-items: center;

    ul {
      display: flex;
      flex-direction: row;
      align-items: center;

      li {
        margin-right: 35px;
        font-size: 18px;
        padding: 7px;
        font-family: Poppins, Helvetica Neue, Arial, sans-serif;
        cursor: pointer;
        transition: all 0.3s;

        :hover {
          :not(:last-child) {
            background: white;
            border-radius: 5px;

            a {
              color: #0e77ff;
            }
          }
        }

        div {
          display: flex;
          justify-content: center;
          align-items: center;
          background: #fff;
          width: 159px;
          height: 53px;
          border-radius: 5px;
          box-shadow: 0 30px 60px rgba(0, 0, 0, 0.15);
          cursor: pointer;

          :hover {
            box-shadow: 0 30px 60px rgba(0, 0, 0, 0.3);
          }
        }
      }
    }
  }
`;

export const A = styled.a`
  color: ${props => props.color};
`;

export const Initialpage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 480px) {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    padding-left: 17px;
    padding-right: 17px;
    margin-top: -30%;
  }

  @media (max-width: 767px) {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    padding-left: 17px;
    padding-right: 17px;
    margin-top: -30%;
  }
`;

export const Rocket = styled.img`
  width: 14.74726420010422vw;
  height: 55.010224948875255vh;
  margin-left: 59%;
  -moz-user-select: none;

  @media (max-width: 480px) {
    width: 50vw;
    margin-top: -13%;
    margin-left: -3%;
    transform: rotate(-27deg);
  }

  @media (max-width: 767px) and (min-width: 481px) {
    display: none;
  }

  @media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) {
    margin-top: 0%;
  }
`;

export const Rocketlaunch = styled.img`
  width: 100vw;
  height: 28.097%;
  margin-left: 0%;
  margin-top: -10%;
  -moz-user-select: none;

  @media (max-width: 480px) {
    display: none;
  }

  @media (max-width: 767px) {
    display: none;
  }
`;

export const LogoMobile = styled.img`
  display: none;

  @media (max-width: 480px) {
    display: flex;
  }

  @media (max-width: 767px) and (min-width: 481px) {
    display: flex;
  }
`;

export const InitialCotent = styled.div`
  margin-top: -42%;
  margin-left: 11%;

  h1 {
    font-family: Poppins, Helvetica Neue, Arial, sans-serif;
    font-size: 55px;
    font-weight: 600;
    color: #fff;
  }

  div {
    width: 38.281%;
    height: 6.771%;
    margin-top: 3.125%;
    margin-bottom: 100px;

    p {
      font-family: Poppins, Helvetica Neue, Arial, sans-serif;
      font-size: 20px;
      font-weight: 300;
      color: #9abfff;
    }
  }

  @media (max-width: 480px) {
    margin-top: 0%;
    margin-left: 0%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;

    h1 {
      text-align: center;
      width: 100%;
      font-size: 38px;
    }

    div {
      margin-top: 50px;
      width: 100%;

      p {
        text-align: center;
        font-size: 16px;
      }
    }

    a {
      margin-top: 7%;
      margin-left: 0;
      margin-bottom: 11vh;
      width: 180px;
      height: 50px;
      border-radius: 30px;
      margin-top: 3.125%;
      background: #fff;
      border: none;
      box-shadow: 0 15px 30px rgba(0, 0, 0, 0.15);
      font-size: 25px;
      font-family: Muli, Helvetica Neue, Arial, sans-serif;
      color: #0e77fc;
      transition: all 0.5s;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
    }
  }

  @media (max-width: 767px) {
    margin-top: 10%;
    margin-left: 0%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;

    h1 {
      text-align: center;
      width: 100%;
      font-size: 38px;
    }

    div {
      margin-top: 50px;
      width: 100%;

      p {
        text-align: center;
        font-size: 16px;
      }
    }

    a {
      margin-top: 7%;
      margin-left: 0;
      margin-bottom: 11vh;
      width: 280px;
      height: 50px;
      border-radius: 30px;
      margin-top: 3.125%;
      background: #fff;
      border: none;
      box-shadow: 0 15px 30px rgba(0, 0, 0, 0.15);
      font-size: 25px;
      font-family: Muli, Helvetica Neue, Arial, sans-serif;
      color: #0e77fc;
      transition: all 0.5s;
      display: flex;
      justify-content: space-evenly;
      align-items: center;

      :before {
        display: none;
      }

      :after {
        display: none;
      }
    }
  }
`;

export const Initialpagetriangles = styled.img`
  margin-left: 35%;
  margin-top: -25%;
  -moz-user-select: none;

  @media (max-width: 480px) {
    display: none;
  }

  @media (max-width: 767px) {
    display: none;
  }
`;

export const Aboutus = styled.div`
  margin-top: 8%;

  @media (max-width: 480px) {
    margin-top: 90%;
  }
`;

export const AboutusTriangles = styled.img`
  margin-top: -60%;
  margin-left: 24%;
  width: 90%;

  @media (max-width: 480px) {
    display: none;
  }
`;

export const Aboutusimage = styled.img`
  margin-left: -40%;
  width: 95%;

  @media (max-width: 480px) {
    margin-top: -100%;
  }
`;

export const Aboutusballs = styled.img`
  margin-top: -5%;
  margin-left: 49%;
  width: 90%;

  @media (max-width: 480px) {
    margin-top: 120%;
  }
`;

export const Aboutuscontent = styled.div`
  margin-left: 58%;
  margin-top: -50%;

  div {
    display: flex;
    align-items: center;

    h3 {
      color: #0e77ff;
      font-family: Poppins, Helvetica Neue, Arial, sans-serif;
      font-size: 25px;
      font-weight: 600;
      margin-left: 5px;
    }

    h1 {
      font-family: Poppins, Helvetica Neue, Arial, sans-serif;
      font-size: 40px;
      font-weight: 600;
      margin-bottom: 40px;
    }

    button {
      margin-top: 20px;
      width: 239px;
      height: 72px;
      border-radius: 50px;
      font-size: 25px;
      font-family: Muli, Helvetica Neue, Arial, sans-serif;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      background: none;
      color: #0e77ff;
      border: 3px solid #0e77ff;
      cursor: pointer;
      transition: all 0.4s;

      :hover {
        background: #0e77ff;
        color: white;
      }
    }

    div {
      width: 30.417%;
      height: 5.521%;

      h4 {
        font-family: Poppins, Helvetica Neue, Arial, sans-serif;
        font-size: 22px;
        font-weight: 400;
      }
    }

    div {
      width: 584px;
      height: 166px;

      h5 {
        font-family: Poppins, Helvetica Neue, Arial, sans-serif;
        font-size: 18px;
        font-weight: 300;
      }
    }
  }

  @media (max-width: 480px) {
    margin-left: 58%;
    margin-top: -90%;
    height: 130vh;
    div {
      h3 {
        font-size: 18px;
        font-weight: 600;
        margin-left: 5px;
      }

      h1 {
        font-family: Poppins, Helvetica Neue, Arial, sans-serif;
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 30px;
      }

      button {
        display: none;
      }

      div {
        h4 {
          font-family: Poppins, Helvetica Neue, Arial, sans-serif;
          font-size: 16px;
          font-weight: 400;
        }
      }

      div {
        width: 584px;
        height: 166px;

        h5 {
          margin-top: 150%;

          font-family: Poppins, Helvetica Neue, Arial, sans-serif;
          font-size: 14px;
          font-weight: 300;
        }
      }
    }
  }
`;

export const Ourservices = styled.div`
  margin-top: 100px;
  width: 100vw;
  height: 90.8997955010225vh;
  background: rgba(14, 119, 255, 0.02);

  div {
    background: rgba(14, 119, 255, 1);
    width: 26.159458051068263vw;
    height: 90.8997955010225vh;
    margin-left: 73.6%;
  }

  section {
    padding-left: 50px;
    display: flex;
    flex-direction: row;
    margin-top: -40%;
    justify-content: space-between;

    section {
      margin-top: 0%;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;

      aside {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        aside {
          display: flex;
          flex-direction: row;
          align-items: center;
        }

        h1 {
          font-family: Poppins, Helvetica Neue, Arial, sans-serif;
          font-size: 40px;
          font-weight: 600;
          height: 50%;
          width: 80%;
        }

        h3 {
          color: #0e77ff;
          font-family: Poppins, Helvetica Neue, Arial, sans-serif;
          font-size: 25px;
          font-weight: 600;
          margin-left: 5px;
        }
      }
    }
  }

  header {
    background: #fff;
    width: 49.375vw;
    height: 65.83844580777096vh;
    box-shadow: 0 30px 60px rgba(14, 119, 255, 0.15);
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    padding-left: 90px;
    padding-top: 45px;
    padding-bottom: 15px;

    ul {
      display: flex;
      flex-direction: row;
      align-items: center;

      h1 {
        font-family: Poppins, Helvetica Neue, Arial, sans-serif;
        font-size: 44px;
        font-weight: 600;
      }

      p {
        font-family: Poppins, Helvetica Neue, Arial, sans-serif;
        font-size: 16px;
        font-weight: 500;
        margin-left: 14px;
      }

      svg {
        margin-right: 30px;
      }
    }

    h3 {
      color: #000;
      font-family: Poppins, Helvetica Neue, Arial, sans-serif;
      font-size: 16px;
      margin-top: 50px;
      font-weight: 400;
      width: 560px;
      height: 152px;
    }
  }

  @media (max-width: 480px) {
    section {
      padding-left: 0px;
      padding-top: 40px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      section {
        aside {
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          aside {
            display: flex;
            flex-direction: row;
            align-items: center;
          }

          h1 {
            font-family: Poppins, Helvetica Neue, Arial, sans-serif;
            font-size: 25px;
            font-weight: 600;
            height: 50%;
            width: 100%;
            text-align: center;
          }
        }

        section {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          ul {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
          }
        }
      }
    }

    header {
      margin-top: 20px;
      background: #fff;
      width: 90%;
      height: 95vh;
      box-shadow: 0 30px 60px rgba(14, 119, 255, 0.15);
      border-radius: 50px;
      padding-left: 7px;
      padding-right: 7px;
      padding-top: 0px;
      padding-bottom: 0px;

      ul {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        h1 {
          font-family: Poppins, Helvetica Neue, Arial, sans-serif;
          font-size: 20px;
          font-weight: 600;
        }

        p {
          font-family: Poppins, Helvetica Neue, Arial, sans-serif;
          font-size: 16px;
          font-weight: 500;
          margin-left: 14px;
        }

        svg {
          width: 40px;
          margin-right: 30px;
        }
      }

      h3 {
        color: #000;
        font-family: Poppins, Helvetica Neue, Arial, sans-serif;
        font-size: 16px;
        width: 100%;
        text-align: center;
        margin-top: 50px;
        font-weight: 400;
      }
    }

    div {
      display: none;
    }
  }
`;

export const Ourservicesbutton = styled.button`
  width: 239px;
  height: 61px;
  border-radius: 16px;
  border: none;
  font-family: Poppins, Helvetica Neue, Arial, sans-serif;
  font-size: 20px;
  font-weight: 600;
  background: ${props =>
    props.active ? '#0E77FF' : 'rgba(22, 178, 255, 0.09)'};
  color: ${props => (props.active ? '#fff' : '#0E77FF')};

  :not(:first-child) {
    margin-left: 15px;
  }

  @media (max-width: 480px) {
    margin-top: 7px;
    :not(:first-child) {
      margin-left: 0px;
    }
  }
`;

export const Workmethod = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    font-family: Poppins, Helvetica Neue, Arial, sans-serif;
    font-size: 40px;
    font-weight: 600;
    @media (max-width: 480px) {
      text-align: center;
    }
  }

  h4 {
    text-align: center;
    margin-top: 20px;
    font-family: Poppins, Helvetica Neue, Arial, sans-serif;
    font-size: 16px;
    font-weight: 500;
    width: 42.395833333333336vw;
    height: 7.668711656441718vh;
    margin-bottom: 80px;

    @media (max-width: 480px) {
      width: 90vw;
    }
  }

  div {
    width: 80vw;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: unset;

    @media (max-width: 480px) {
      width: 100vh;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      height: 400vh;
    }
  }

  @media (max-width: 480px) {
    margin-top: 160%;
    height: 300vh;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 480px) and (max-height: 900) {
    margin-top: 160%;
    height: 250vh;
    justify-content: center;
    align-items: center;
  }
`;
export const Workmethodcard = styled.section`
  background: ${props => props.background};
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;

  box-shadow: ${props =>
    props.background === '#fff'
      ? '0 30px 60px rgba(14, 119, 255, 0.15)'
      : 'none'};
  width: ${props => (props.background === '#fff' ? '27.031vw' : '19.739vw')};
  height: ${props => (props.background === '#fff' ? '42.638vh' : '35.582vh')};
  padding: 20px;

  @media (max-width: 480px) {
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 90vw;
    height: 70vh;
    background: #fff;
    box-shadow: 0 30px 60px rgba(14, 119, 255, 0.15);
  }

  h1 {
    margin-top: 20px;
    font-family: Poppins, Helvetica Neue, Arial, sans-serif;
    font-size: 30px;
    font-weight: 600;

    @media (max-width: 480px) {
      text-align: center;
      width: 100vw;
    }
  }

  h4 {
    text-align: left;
    margin-top: 20px;
    font-family: Poppins, Helvetica Neue, Arial, sans-serif;
    font-size: 16px;
    font-weight: regular;
    width: 19.739583333333332vw;
    height: 17.38241308793456vh;

    @media (max-width: 480px) {
      width: 80vw;
      text-align: center;
    }
  }

  div {
    display: flex;
    justify-content: start;
    background: #0e77ff;
    width: 15%;
    height: 10px;

    @media (max-width: 480px) {
      width: 8.75vw;
      height: 0.6134969325153374vh;
    }
  }

  p {
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${props =>
      props.background === '#fff' ? '#0E77FF' : 'rgba(22, 178, 255, 0.09)'};
    color: ${props => (props.background === '#fff' ? '#fff' : '#0E77FF')};
    width: 121px;
    height: 45px;
    border-radius: 20px;
  }
`;

export const Workmethodimage = styled.img`
  width: 40%;
  height: 40%;
  margin-top: -8%;
  margin-left: -11%;
`;

export const Maintechnologies = styled.div`
  height: 100vh;
  background: rgba(14, 119, 255, 0.02);

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1 {
      font-family: Poppins, Helvetica Neue, Arial, sans-serif;
      font-size: 40px;
      font-weight: 600;
    }

    h4 {
      margin-top: 20px;
      text-align: center;
      font-family: Poppins, Helvetica Neue, Arial, sans-serif;
      font-size: 16px;
      font-weight: 500;
      width: 42.396%;
      height: 6.944%;
    }

    ul {
      margin-top: 40px;
      width: 694px;
      height: 81px;
      background: #fff;
      box-shadow: 0 30px 60px rgba(14, 119, 255, 0.15);
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      border-radius: 50px;
    }

    section {
      width: 70%;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      margin-top: 60px;
    }
  }

  @media (max-width: 480px) {
    div {
      h1 {
        width: 100vw;
        text-align: center;
      }
      h4 {
        width: 90vw;
      }

      ul {
        width: 95vw;
        background: #fff;
        box-shadow: 0 30px 60px rgba(14, 119, 255, 0.15);
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex-direction: row;
      }

      section {
        width: 100vw;
        display: flex;
        justify-content: space-evenly;
      }
    }
  }
`;

export const Maintechnologiesli = styled.li`
  width: 100%;
  font-family: Poppins, Helvetica Neue, Arial, sans-serif;
  font-size: 20px;
  font-weight: 400;
  color: ${props => (props.active ? '#0E77FF' : '#000')};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  :not(:first-child) {
    border-left: 2px solid #000;
  }

  @media (max-width: 480px) {
    font-size: 16px;

    :not(:first-child) {
      border-left: none;
    }
  }
`;

export const Brands = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${props => props.background};
  width: 100px;
  height: 100px;
  transform: rotate(-30deg) skew(25deg);
  box-shadow: -20px 30px 20px rgba(14, 119, 255, 0.5);
  transition: 0.5s;

  :hover {
    transform: perspective(1000px) rotate(-30deg) skew(25deg)
      translate(20px, -20px);
    box-shadow: -50px 50px 60px rgba(14, 119, 255, 0.5);
  }

  :before {
    content: '';
    position: absolute;
    top: 10px;
    left: -20px;
    height: 100%;
    width: 20px;
    ${props => props.before};
    background: ${props => props.before || '#ededed'};
    transition: 0.5s;
    transform: rotate(0deg) skewY(-45deg);
  }

  :after {
    content: '';
    position: absolute;
    bottom: -20px;
    left: -10px;
    height: 20px;
    width: 100%;
    background: ${props => props.after || '#f7f7f7'};
    transition: 0.5s;
    transform: rotate(0deg) skewX(-45deg);
  }

  img {
    width: 72px;
    height: 72px;
  }

  @media (max-width: 480px) {
    width: 65px;
    height: 65px;

    svg {
      width: 40px;
    }

    img {
      width: 40px;
      height: 40px;
    }
  }
`;

export const Contactus = styled.section`
  width: 100vw;
  height: 80vh;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;

  aside {
    width: 90vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  form {
    width: 40%;
    height: 90%;
    border-radius: 60px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    background: #fff;
    box-shadow: 0 30px 60px rgba(14, 119, 255, 0.25);

    input,
    textarea {
      width: 70%;
      padding: 7px 7px;
      color: #000;
      border: 2px solid #0e77ff;
      font-size: 18px;
      border-radius: 4px;
      font-weight: 400;
    }

    button {
      width: 70%;
      height: 10%;
      border: none;
      border-radius: 4px;
      background: #68bae5;
      color: #fff;
    }
  }

  @media (max-width: 480px) {
    margin-top: 90%;
    flex-direction: column-reverse;

    aside {
      div {
        svg {
          display: none;
        }
      }
    }
  }
`;

export const Footer = styled.footer`
  height: 20vh;
  background: #0e77ff;
`;
