import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100%;
  background: rgba(236, 241, 248);

  table {
    button {
      display: flex;
      justify-content: center;
      border-radius: 50%;
      border: none;
      width: 50px;
      height: 50px;
      :hover {
        background: #eee;
      }
    }
  }
`;
