/* eslint-disable react/jsx-boolean-value */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable jsx-a11y/A nchor-is-valid */
import React, { useState } from 'react';
import { Form, Input } from '@rocketseat/unform';
import Lottie from 'react-lottie';
import {
  FaLaptopCode,
  FaMobile,
  FaCloud,
  FaReact,
  FaAws,
  FaJs,
  FaDocker,
  FaNode,
  FaDigitalOcean,
  FaGithub,
  FaHtml5,
  FaCss3,
  FaFigma,
  FaTwitter,
  FaFacebook,
  FaInstagram,
  FaDiscord,
  FaYoutube,
  FaBars,
  FaTimes,
  FaPlay,
} from 'react-icons/fa';

import { DiIllustrator, DiPhotoshop } from 'react-icons/di';
import animationData from './meatoffice.json';

// Assets
import logonav from '~/assets/logocodegalaxy.svg';
import rocket from '~/assets/rocket.svg';
import rocket_launch from '~/assets/rocket_launch.svg';
import initial_page_triangles from '~/assets/initial_page_triangles.svg';
import aboutus from '~/assets/aboutus.svg';
import start from '~/assets/start.svg';
import aboutus_triangle from '~/assets/aboutus_triangle.svg';
import aboutus_balls from '~/assets/aboutus_balls.svg';
import advantage from '~/assets/advantage.svg';
import uiuxdesigning from '~/assets/uiuxdesign.svg';
import workmethodtriangles from '~/assets/workmethodtriangles.svg';
import redux from '~/assets/redux.svg';
import adobexd from '~/assets/adobexd.svg';

import './stylescss.css';

import {
  Container,
  Navbar,
  Navbarcontent,
  A,
  Rocket,
  Rocketlaunch,
  InitialCotent,
  Initialpagetriangles,
  Aboutus,
  Aboutusimage,
  Aboutuscontent,
  AboutusTriangles,
  Aboutusballs,
  Ourservices,
  Ourservicesbutton,
  Workmethod,
  Workmethodcard,
  Workmethodimage,
  Maintechnologies,
  Maintechnologiesli,
  Brands,
  Contactus,
  Initialpage,
  LogoMobile,
  Footer,
} from './styles';

export default function index() {
  const [webDevelopment, setWebDevelopment] = useState(true);
  const [mobileDevelopment, setMobileDevelopment] = useState(false);
  const [uiuxDesigning, setUiuxDesigning] = useState(false);
  const [cloudTechnology, setCloudTechnology] = useState(false);
  const [design, setDesign] = useState(false);
  const [development, setDevelopment] = useState(true);
  const [marketing, setMarketing] = useState(false);
  const [menu, setMenu] = useState(false);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  function handleWebDevelopment() {
    setWebDevelopment(true);
    setMobileDevelopment(false);
    setUiuxDesigning(false);
    setCloudTechnology(false);
  }

  function handleMobileDevelopment() {
    setWebDevelopment(false);
    setMobileDevelopment(true);
    setUiuxDesigning(false);
    setCloudTechnology(false);
  }

  function handleUiuxDesigning() {
    setWebDevelopment(false);
    setMobileDevelopment(false);
    setUiuxDesigning(true);
    setCloudTechnology(false);
  }

  function handleCloudTechnology() {
    setWebDevelopment(false);
    setMobileDevelopment(false);
    setUiuxDesigning(false);
    setCloudTechnology(true);
  }

  function handleDesign() {
    setDesign(true);
    setDevelopment(false);
    setMarketing(false);
  }

  function handleDevelopment() {
    setDesign(false);
    setDevelopment(true);
    setMarketing(false);
  }

  function handleMarketing() {
    setDesign(false);
    setDevelopment(false);
    setMarketing(true);
  }

  function handleCloseMenu() {
    setMenu(false);
  }

  function handleOpenMenu() {
    setMenu(true);
  }

  return (
    <Container>
      <Navbar>
        <section>
          <FaBars onClick={handleOpenMenu} color="#fff" size={27} />
          <LogoMobile src={logonav} height={40} widht={40} />
        </section>
        {menu ? (
          <nav>
            <aside>
              <FaTimes onClick={handleCloseMenu} color="#fff" size={27} />
            </aside>
            <ul>
              <li>
                <A color="#fff" href="#aboutus">
                  Sobre
                </A>
              </li>
              <li>
                <A color="#fff" href="#workmethod">
                  Método de Trabalho
                </A>
              </li>
              <li>
                <A color="#fff" href="#technologies">
                  Tecnologias
                </A>
              </li>
              <li>
                <A color="#fff" href="#contactus">
                  Contato
                </A>
              </li>
            </ul>
          </nav>
        ) : (
          <></>
        )}
        <Navbarcontent>
          <nav>
            <img src={logonav} alt="" height={84.79} width={85.13} />
            <p>CodeGalaxy</p>
            <p className="blink">_</p>
          </nav>
          <aside>
            <ul>
              <li>
                <A color="#fff" href="#aboutus">
                  Sobre
                </A>
              </li>
              <li>
                <A color="#fff" href="#workmethod">
                  Método de Trabalho
                </A>
              </li>
              <li>
                <A color="#fff" href="#technologies">
                  Tecnologias
                </A>
              </li>
              <li>
                <A color="#fff" href="#contactus">
                  Contato
                </A>
              </li>
              <li>
                <div className="buttonLogin">
                  <A href="#" color="#0E77FC">
                    Login
                  </A>
                </div>
              </li>
            </ul>
          </aside>
        </Navbarcontent>
      </Navbar>
      <Initialpage>
        <Rocket src={rocket} />
        <Rocketlaunch src={rocket_launch} />
        <InitialCotent className="initial-content">
          <aside>
            <h1>Mobile & Web Development</h1>
          </aside>
          <div>
            <p>
              Nossos produtos são totalmente costumizados com exclusividade para
              cada cliente, construídos com as mais modernas tecnologias
            </p>
          </div>
          <a href="#contactus" className="button" type="button">
            Começar <FaPlay />
          </a>
        </InitialCotent>
        <Initialpagetriangles src={initial_page_triangles} />
        <span className="scrollDown" />
      </Initialpage>
      <Aboutus id="aboutus">
        <Aboutusimage src={aboutus} />
        <Aboutuscontent>
          <AboutusTriangles src={aboutus_triangle} />
          <div>
            <img src={start} alt="" width={38.01} height={46.91} />
            <h3>Sobre Nós</h3>
          </div>
          <div>
            <h1>Digital Agency</h1>
          </div>
          <div>
            <div>
              <h4>
                Nossos produtos são totalmente costumizados com exclusividade
                para cada cliente, construídos com as mais modernas tecnologias.
              </h4>
            </div>
          </div>
          <div>
            <div>
              <h5>
                Nosso objetivo é concretizar o seu sonho de ter um
                empreendimento de sucesso. Para isso montaremos estratégias
                específicas para cada cliente, integrando o marketing digital
                com o desnvolvimento web e mobile. Conheceremos sua marca e
                montaremos um plano para realizar os seus sonhos.
              </h5>
            </div>
          </div>
          <div>
            <button type="button">
              Começar <FaPlay />
            </button>
          </div>
          <Aboutusballs src={aboutus_balls} />
        </Aboutuscontent>
      </Aboutus>
      <Ourservices>
        <div />
        <section>
          <section>
            <aside>
              <aside>
                <img src={start} alt="" width={38.01} height={46.91} />
                <h3>Sobre Nós</h3>
              </aside>
              <h1>Transformamos suas ideias em realidade</h1>
            </aside>
            <section>
              <ul>
                <Ourservicesbutton
                  onClick={handleWebDevelopment}
                  active={webDevelopment}
                  type="button"
                >
                  Web Development
                </Ourservicesbutton>
                <Ourservicesbutton
                  onClick={handleMobileDevelopment}
                  active={mobileDevelopment}
                  type="button"
                >
                  Mobile Development
                </Ourservicesbutton>
              </ul>
              <br />
              <ul>
                <Ourservicesbutton
                  onClick={handleUiuxDesigning}
                  active={uiuxDesigning}
                  type="button"
                >
                  UI/UX Designing
                </Ourservicesbutton>
                <Ourservicesbutton
                  onClick={handleCloudTechnology}
                  active={cloudTechnology}
                  type="button"
                >
                  Cloud Technology
                </Ourservicesbutton>
              </ul>
            </section>
          </section>
          <header>
            {webDevelopment ? (
              <>
                <ul>
                  <FaLaptopCode size={97} color="#0e77ff" />
                  <h1>Web Development</h1>
                </ul>
                <h3>
                  Lorem ipsum dolor sit amet, conse ctetur adipi scing elit.
                  Aliq uam ex odio, is the turpis accu msan congue euisque
                  blandit dui Pelle ntesque habitant.
                </h3>
                <ul className="advantages">
                  <img src={advantage} height={41} widht={41} alt="" />
                  <p>
                    Lorem ipsum dolor sit amet, conse ctetur adipi scing elit.
                  </p>
                </ul>
                <ul className="advantages">
                  <img src={advantage} height={41} widht={41} alt="" />
                  <p>
                    Lorem ipsum dolor sit amet, conse ctetur adipi scing elit.
                  </p>
                </ul>
                <ul className="advantages">
                  <img src={advantage} height={41} widht={41} alt="" />
                  <p>
                    Lorem ipsum dolor sit amet, conse ctetur adipi scing elit.
                  </p>
                </ul>
                <ul className="advantages">
                  <img src={advantage} height={41} widht={41} alt="" />
                  <p>
                    Lorem ipsum dolor sit amet, conse ctetur adipi scing elit.
                  </p>
                </ul>
              </>
            ) : (
              <>
                {mobileDevelopment ? (
                  <>
                    <ul>
                      <FaMobile size={97} color="#0e77ff" />
                      <h1>Mobile Development</h1>
                    </ul>
                    <h3>
                      Lorem ipsum dolor sit amet, conse ctetur adipi scing elit.
                      Aliq uam ex odio, is the turpis accu msan congue euisque
                      blandit dui Pelle ntesque habitant.
                    </h3>
                    <ul className="advantages">
                      <img src={advantage} height={41} widht={41} alt="" />
                      <p>
                        Lorem ipsum dolor sit amet, conse ctetur adipi scing
                        elit.
                      </p>
                    </ul>
                    <ul className="advantages">
                      <img src={advantage} height={41} widht={41} alt="" />
                      <p>
                        Lorem ipsum dolor sit amet, conse ctetur adipi scing
                        elit.
                      </p>
                    </ul>
                    <ul className="advantages">
                      <img src={advantage} height={41} widht={41} alt="" />
                      <p>
                        Lorem ipsum dolor sit amet, conse ctetur adipi scing
                        elit.
                      </p>
                    </ul>
                    <ul className="advantages">
                      <img src={advantage} height={41} widht={41} alt="" />
                      <p>
                        Lorem ipsum dolor sit amet, conse ctetur adipi scing
                        elit.
                      </p>
                    </ul>
                  </>
                ) : (
                  <>
                    {uiuxDesigning ? (
                      <>
                        <ul className="header">
                          <img src={uiuxdesigning} alt="" />
                          <h1>UI/UX Designing</h1>
                        </ul>
                        <h3>
                          Lorem ipsum dolor sit amet, conse ctetur adipi scing
                          elit. Aliq uam ex odio, is the turpis accu msan congue
                          euisque blandit dui Pelle ntesque habitant.
                        </h3>
                        <ul className="advantages">
                          <img src={advantage} height={41} widht={41} alt="" />
                          <p>
                            Lorem ipsum dolor sit amet, conse ctetur adipi scing
                            elit.
                          </p>
                        </ul>
                        <ul className="advantages">
                          <img src={advantage} height={41} widht={41} alt="" />
                          <p>
                            Lorem ipsum dolor sit amet, conse ctetur adipi scing
                            elit.
                          </p>
                        </ul>
                        <ul className="advantages">
                          <img src={advantage} height={41} widht={41} alt="" />
                          <p>
                            Lorem ipsum dolor sit amet, conse ctetur adipi scing
                            elit.
                          </p>
                        </ul>
                        <ul className="advantages">
                          <img src={advantage} height={41} widht={41} alt="" />
                          <p>
                            Lorem ipsum dolor sit amet, conse ctetur adipi scing
                            elit.
                          </p>
                        </ul>
                      </>
                    ) : (
                      <>
                        {cloudTechnology ? (
                          <>
                            <ul className="header">
                              <FaCloud size={97} color="#0e77ff" />
                              <h1>Cloud Technology</h1>
                            </ul>
                            <h3>
                              Lorem ipsum dolor sit amet, conse ctetur adipi
                              scing elit. Aliq uam ex odio, is the turpis accu
                              msan congue euisque blandit dui Pelle ntesque
                              habitant.
                            </h3>
                            <ul className="advantages">
                              <img
                                src={advantage}
                                height={41}
                                widht={41}
                                alt=""
                              />
                              <p>
                                Lorem ipsum dolor sit amet, conse ctetur adipi
                                scing elit.
                              </p>
                            </ul>
                            <ul className="advantages">
                              <img
                                src={advantage}
                                height={41}
                                widht={41}
                                alt=""
                              />
                              <p>
                                Lorem ipsum dolor sit amet, conse ctetur adipi
                                scing elit.
                              </p>
                            </ul>
                            <ul className="advantages">
                              <img
                                src={advantage}
                                height={41}
                                widht={41}
                                alt=""
                              />
                              <p>
                                Lorem ipsum dolor sit amet, conse ctetur adipi
                                scing elit.
                              </p>
                            </ul>
                            <ul className="advantages">
                              <img
                                src={advantage}
                                height={41}
                                widht={41}
                                alt=""
                              />
                              <p>
                                Lorem ipsum dolor sit amet, conse ctetur adipi
                                scing elit.
                              </p>
                            </ul>
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </header>
        </section>
      </Ourservices>
      <Workmethod id="workmethod">
        <h1>Método de Trabalho</h1>
        <h4>
          Lorem ipsum dolor sit amet, conse ctetur adipi scing elit. Aliq uam ex
          odio, is the turpis accu msan congue euisque blandit dui Pelle ntesque
          habitant.
        </h4>
        <div>
          <Workmethodcard background="none">
            <p>PASSO 1</p>
            <h1>Envie seu resumo</h1>
            <div />
            <h4>
              Lorem ipsum dolor sit amet, conse ctetur adipi scing elit. Aliq
              uam ex odio, is the turpis accu msan congue euisque blandit dui
              Pelle ntesque habitant.
            </h4>
          </Workmethodcard>
          <Workmethodcard background="#fff">
            <p>PASSO 2</p>
            <h1>Espere pela entrega</h1>
            <div />
            <h4>
              Lorem ipsum dolor sit amet, conse ctetur adipi scing elit. Aliq
              uam ex odio, is the turpis accu msan congue euisque blandit dui
              Pelle ntesque habitant.
            </h4>
          </Workmethodcard>
          <Workmethodcard background="none">
            <p>PASSO 3</p>
            <h1>Pege seus arquivos!</h1>
            <div />
            <h4>
              Lorem ipsum dolor sit amet, conse ctetur adipi scing elit. Aliq
              uam ex odio, is the turpis accu msan congue euisque blandit dui
              Pelle ntesque habitant.
            </h4>
          </Workmethodcard>
        </div>
      </Workmethod>
      <Maintechnologies id="technologies">
        <Workmethodimage src={workmethodtriangles} alt="" />
        <div>
          <h1>Principais Tecnologias</h1>
          <h4>
            Lorem ipsum dolor sit amet, conse ctetur adipi scing elit. Aliq uam
            ex odio, is the turpis accu msan congue euisque blandit dui Pelle
            ntesque habitant.
          </h4>
          <ul>
            <Maintechnologiesli onClick={handleDesign} active={design}>
              Design
            </Maintechnologiesli>
            <Maintechnologiesli
              onClick={handleDevelopment}
              active={development}
            >
              Desenvolvimento
            </Maintechnologiesli>
            <Maintechnologiesli onClick={handleMarketing} active={marketing}>
              Marketing
            </Maintechnologiesli>
          </ul>
          {development ? (
            <>
              <section>
                <Brands background="#fff">
                  <FaReact size={72} color="#0E77FF" />
                </Brands>
                <Brands background="#0E77FF" before="#1475f5" after="#0f71f2">
                  <FaAws size={72} color="#fff" />
                </Brands>
                <Brands background="#fff">
                  <FaDigitalOcean size={72} color="#0E77FF" />
                </Brands>
                <Brands background="#fff">
                  <img src={redux} alt="" />
                </Brands>
                <Brands background="#fff">
                  <FaNode size={72} color="#0E77FF" />
                </Brands>
              </section>
              <section>
                <Brands background="#fff">
                  <FaJs size={72} color="#0E77FF" />
                </Brands>
                <Brands background="#fff">
                  <FaHtml5 size={72} color="#0E77FF" />
                </Brands>
                <Brands background="#fff">
                  <FaCss3 size={72} color="#0E77FF" />
                </Brands>
                <Brands background="#0E77FF" before="#1475f5" after="#0f71f2">
                  <FaGithub size={72} color="#fff" />
                </Brands>
                <Brands background="#fff">
                  <FaDocker size={72} color="#0E77FF" />
                </Brands>
              </section>
            </>
          ) : (
            <>
              {design ? (
                <>
                  <section>
                    <Brands background="#fff">
                      <DiPhotoshop size={72} color="#0E77FF" />
                    </Brands>
                    <Brands
                      background="#0E77FF"
                      before="#1475f5"
                      after="#0f71f2"
                    >
                      <DiIllustrator size={72} color="#fff" />
                    </Brands>
                    <Brands background="#fff">
                      <img src={adobexd} alt="" />
                    </Brands>
                    <Brands background="#fff">
                      <FaFigma size={72} color="#0E77FF" />
                    </Brands>
                  </section>
                </>
              ) : (
                <>
                  {marketing ? (
                    <>
                      <section>
                        <Brands background="#fff">
                          <FaFacebook size={72} color="#0E77FF" />
                        </Brands>
                        <Brands
                          background="#0E77FF"
                          before="#1475f5"
                          after="#0f71f2"
                        >
                          <FaTwitter size={72} color="#fff" />
                        </Brands>
                        <Brands background="#fff">
                          <FaInstagram size={72} color="#0E77FF" />
                        </Brands>
                        <Brands background="#fff">
                          <FaDiscord size={72} color="#0E77FF" />
                        </Brands>
                        <Brands background="#fff">
                          <FaYoutube size={72} color="#0E77FF" />
                        </Brands>
                      </section>
                    </>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </Maintechnologies>
      <Contactus id="contactus">
        <aside>
          <Lottie options={defaultOptions} viewBox={100} />
        </aside>
        <Form>
          <Input name="email" type="email" placeholder="E-mail" />
          <Input name="company" type="text" placeholder="Empresa" />
          <Input name="name" type="text" placeholder="Nome completo" />
          <Input
            name="text"
            type="text"
            placeholder="Texto"
            multiline
            rows={6}
          />
          <button type="submit">Enviar</button>
        </Form>
      </Contactus>
      <Footer />
    </Container>
  );
}
